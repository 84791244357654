import React, { useState, useEffect } from 'react';
import {
   Admin,
   CustomRoutes,
   Resource,
   Title,
   useRedirect
} from 'react-admin'

import { Card, CardContent } from '@mui/material'

import './App.css'

import {
   BrandList,
   BrandEdit,
   BrandCreate,
} from './modules/brands'

import {
   AffPrioritiesList,
   AffPriorityCreate,
   AffPriorityEdit,
} from './modules/affiliate-priorities'

import {
   AffiliateApiSettingList,
   AffiliateApiSettingCreate,
   AffiliateApiSettingEdit,
} from './modules/affiliate-api-settings'

import {
   BrandTagsList,
   BrandTagsEdit,
   BrandTagsCreate,
} from './modules/brand-iframe-tags'

import {
   BlockEventSettingsList,
   BlockEventSettingEdit,
   BlockEventSettingCreate,
   BlockEventSettingsAggregatedList,
} from './modules/block-event-settings'

import {
   GoalList,
   GoalEdit,
   GoalCreate,
   GoalAggregatedList,
} from './modules/goals'

import {
   OrderApprovalList,
   OrderApprovalAggregatedList,
} from './modules/order-approval'

import {
   PreApprovalSettingsEdit,
   PreApprovalSettingsCreate,
   PreApprovalSettingsList,
   PreApprovalSettingsAggregatedList,
} from './modules/pre-approval-settings'

import {
   PostbackSettingsList,
   PostbackSettingsEdit,
   PostbackSettingsCreate,
} from './modules/postback-settings'

import {
   CouponEventSettingsCreate,
   CouponEventSettingsEdit,
   CouponEventSettingsList,
} from './modules/coupon-event-settings'

import {
   ConflictSettingList,
   ConflictSettingEdit,
   ConflictSettingCreate,
} from './modules/conflict-settings'
import {
   NotificationsEmailsCreate,
   NotificationsEmailsEdit,
   NotificationsEmailsList,
   NotificationsEmailsAggregatedList,
} from './modules/notifications-emails'

import {
   BlockHashedEmailsList,
   BlockHashedEmailsAggregatedList,
} from './modules/block-hashed-emails'

import { MainLayout } from './Layout/MainLayout'
import {
   UsersCreate,
   UsersEdit,
   UsersList,
} from './modules/users'

import {
   CronSettingsCreate,
   CronSettingsEdit,
   CronSettingsList,
} from './modules/cron-settings'

import {
   ElasticCronSettingsCreate,
   ElasticCronSettingsEdit,
   ElasticCronSettingsList,
} from './modules/elastic-cron-settings'

import {
   CurrencyList
} from './modules/currency'

import {
   NotificationsList,
   NotificationsAggregatedList,
} from './modules/notifications'

import {
   ShopifySessionsList,
   ShopifySessionsEdit,
   ShopifySessionsCreate,
} from './modules/shopify-sessions'

import {
   OffersManagementList,
   OffersManagementEdit
} from './modules/offers-management'

import {
   ActionLogsList
} from './modules/action-logs'

import { authProvider, isAllowedResource } from './config/auth-provider'
import { apiUrl, httpClient } from './config/http-client'
import { getCustomRoutes } from './config/customRoutes'
import { dataProvider } from './config/data-provider'
import { lightTheme } from './Layout/themes'

const DefaultPage = () => (
   <Card className="mt-3">
      <Title title="Dashboard"/>
      <CardContent>Welcome to the AffsMedia TrackCB admin panel</CardContent>
   </Card>
)

const App = () => {
   const [scaleFactor, setScaleFactor] = useState(1)
   const redirect = useRedirect()
   const checkSession = async () => {
      try {
         await httpClient(`${apiUrl}/check-session`)
      } catch (err) {
         if (window.location.href.split('/').pop() !== 'login') {
            localStorage.removeItem('userId')
            localStorage.removeItem('permissions')
            redirect('/login')
         }
      }

      return null
   }

   useEffect(() => {
      checkSession();

      const isDev = document.location.hostname.includes('dev')
      document.title = isDev ? 'Dev AM TrackCB Dashboard' : 'AffsMedia TrackCB Dashboard'

      const intervalInst = setInterval(async () => {
         checkSession();
      }, 10000)

      return () => {
         clearInterval(intervalInst)
      }
   }, [])

   useEffect(() => {
      const scaleOfScreen = window.devicePixelRatio;

      if (scaleOfScreen > 1 && scaleOfScreen < 1.5) {
         setScaleFactor(1.33);
      } else if (scaleOfScreen >= 1.5) {
         setScaleFactor(1.49);
      } else {
         setScaleFactor(1);
      }
   }, []);

   useEffect(() => {
      const handleVisibilityChange = () => {
        if (!document.hidden) {
          checkSession();
        }
      };
  
      document.addEventListener('visibilitychange', handleVisibilityChange);
  
      return () => document.removeEventListener('visibilitychange', handleVisibilityChange);
    }, []);

   const calculatedHeight = `${100 * scaleFactor}vh`

   return (
      <div className="admin-wrapper" style={{ height: calculatedHeight, overflowY: 'scroll' }}>
         <Admin dashboard={DefaultPage}
                authProvider={authProvider}
                dataProvider={dataProvider}
                layout={MainLayout}
                theme={lightTheme}
         >
            {permissions => [
               <CustomRoutes>
                  {getCustomRoutes(permissions)}
               </CustomRoutes>,
               isAllowedResource(permissions, 'brand') ?
                  <Resource name="brand"
                            list={BrandList}
                            edit={BrandEdit}
                            create={BrandCreate}
                  /> : null,

               isAllowedResource(permissions, 'brand-iframe-tags') ?
                  <Resource name="brand-iframe-tags"
                            list={BrandTagsList}
                            edit={BrandTagsEdit}
                            create={BrandTagsCreate}
                  /> : null,

               isAllowedResource(permissions, 'goals') ?
                  <Resource name="goals"
                            list={GoalList}
                            edit={GoalEdit}
                            create={GoalCreate}
                  /> : null,

               isAllowedResource(permissions, 'goals-aggregated') ?
                  <Resource name="goals-aggregated"
                            list={GoalAggregatedList}
                  /> : null,

               isAllowedResource(permissions, 'notification-emails') ?
                  <Resource name="notification-emails"
                            list={NotificationsEmailsList}
                            edit={NotificationsEmailsEdit}
                            create={NotificationsEmailsCreate}
                  /> : null,

               isAllowedResource(permissions, 'notification-emails-aggregated') ?
                  <Resource name="notification-emails-aggregated"
                            list={NotificationsEmailsAggregatedList}
                  /> : null,

               isAllowedResource(permissions, 'conflict-settings') ?
                  <Resource name="conflict-settings"
                            list={ConflictSettingList}
                            edit={ConflictSettingEdit}
                            create={ConflictSettingCreate}
                  /> : null,

               isAllowedResource(permissions, 'order-approval') ?
                  <Resource name="order-approval"
                            list={OrderApprovalList}
                  /> : null,
               
               isAllowedResource(permissions, 'order-approval-aggregated-by-brand') ?
                  <Resource name="order-approval-aggregated-by-brand"
                            list={OrderApprovalAggregatedList}
                  /> : null,

               isAllowedResource(permissions, 'order-approval-aggregated-by-affiliate') ?
                  <Resource name="order-approval-aggregated-by-affiliate"
                            list={OrderApprovalAggregatedList}
                  /> : null,

               isAllowedResource(permissions, 'pre-approval-settings') ?
                  <Resource name="pre-approval-settings"
                            list={PreApprovalSettingsList}
                            edit={PreApprovalSettingsEdit}
                            create={PreApprovalSettingsCreate}/>
                  : null,

               isAllowedResource(permissions, 'pre-approval-settings-aggregated-by-affiliate')
                  ? <Resource name="pre-approval-settings-aggregated-by-affiliate" list={PreApprovalSettingsAggregatedList}/>
                  : null,
               
               isAllowedResource(permissions, 'pre-approval-settings-aggregated-by-offer')
                  ? <Resource name="pre-approval-settings-aggregated-by-offer" list={PreApprovalSettingsAggregatedList}/>
                  : null,

               isAllowedResource(permissions, 'postback-settings') ?
                  <Resource name="postback-settings"
                            list={PostbackSettingsList}
                            edit={PostbackSettingsEdit}
                            create={PostbackSettingsCreate}
                  /> : null,

               isAllowedResource(permissions, 'block-event-settings') ?
                  <Resource name="block-event-settings"
                            list={BlockEventSettingsList}
                            edit={BlockEventSettingEdit}
                            create={BlockEventSettingCreate}/>
                  : null,

               isAllowedResource(permissions, 'block-event-settings-aggregated') ?
                  <Resource name="block-event-settings-aggregated"
                            list={BlockEventSettingsAggregatedList}
                  /> : null,

               isAllowedResource(permissions, 'block-hashed-emails') ?
                  <Resource name="block-hashed-emails"
                            list={BlockHashedEmailsList}
                     // edit={BlockHashedEmailsEdit}
                     // create={BlockHashedEmailsCreate}
                  />
                  : null,

               isAllowedResource(permissions, 'block-hashed-emails-aggregated') ?
                  <Resource name="block-hashed-emails-aggregated"
                            list={BlockHashedEmailsAggregatedList}
                  />
                  : null,

               isAllowedResource(permissions, 'coupon-settings') ?
                  <Resource name="coupon-settings"
                            list={CouponEventSettingsList}
                            edit={CouponEventSettingsEdit}
                            create={CouponEventSettingsCreate}/>
                  : null,

               isAllowedResource(permissions, 'aff-priorities') ?
                  <Resource name="aff-priorities"
                            list={AffPrioritiesList}
                            edit={AffPriorityEdit}
                            create={AffPriorityCreate}
                  />
                  : null,

               isAllowedResource(permissions, 'shopify-sessions') ?
                  <Resource name="shopify-sessions"
                            list={ShopifySessionsList}
                            edit={ShopifySessionsEdit}
                            create={ShopifySessionsCreate}
                  />
                  : null,

               isAllowedResource(permissions, 'affiliate-api-settings') ?
                  <Resource name="affiliate-api-settings"
                            list={AffiliateApiSettingList}
                            edit={AffiliateApiSettingEdit}
                            create={AffiliateApiSettingCreate}
                  /> : null,

               isAllowedResource(permissions, 'cron-settings') ?
                  <Resource name="cron-settings"
                            list={CronSettingsList}
                            edit={CronSettingsEdit}
                            create={CronSettingsCreate}
                  /> : null,

               isAllowedResource(permissions, 'elastic-cron-settings') ?
                  <Resource name="elastic-cron-settings"
                            list={ElasticCronSettingsList}
                            edit={ElasticCronSettingsEdit}
                            create={ElasticCronSettingsCreate}
                  /> : null,

               isAllowedResource(permissions, 'currency') ?
                  <Resource name="currency"
                            list={CurrencyList}
                  /> : null,

               isAllowedResource(permissions, 'notifications') ?
                  <Resource name="notifications"
                            list={NotificationsList}
                  /> : null,

               // isAllowedResource(permissions, 'notifications-aggregated') ?
               //    <Resource name="notifications-aggregated"
               //              list={NotificationsAggregatedList}
               //    /> : null,

               isAllowedResource(permissions, 'users') ?
                  <Resource name="users"
                            list={UsersList}
                            edit={UsersEdit}
                            create={UsersCreate}
                  />
                  : null,


               isAllowedResource(permissions, 'offers') ?
                  <Resource name="offers"
                            list={OffersManagementList}
                            edit={OffersManagementEdit}
                  /> : null,

               isAllowedResource(permissions, 'action-logs') ?
                  <Resource name="action-logs"
                            list={ActionLogsList}
                  /> : null,
            ]}
         </Admin>
      </div>

   )
}

export default App
